import { axiosWrapper } from './axiosWrapper'

export const getOrdersSummary = async (source: string, params: string) => {
  const response = await axiosWrapper.get(
    `/ers/v1/clients/${source}/cases/summary?${params}&orderClassificationType=INBOUND`
  )
  return response.data
}

export const fetchFleetsBySourceId = async (souceId: string) => {
  const response = await axiosWrapper.get(`/ers/v1/clients/${souceId}/fleets`)
  return response.data
}

export const getActivityLogsByCaseId = async (caseId: string) => {
  const response = await axiosWrapper.get(`/ers/v1/logs/cases/${caseId}`)
  return response.data
}

export const fetchReadSignedApi = async (channel_id, fileName) => {
  const response = await axiosWrapper.get(
    `/ers/v1/fru/v1/fs/d/read/case/${channel_id}/file/${fileName}`
  )
  return response
}

export const getEpcrReportSignedUrl = async (orderId: string) => {
  const response = await axiosWrapper.get(
    `/ers/v1/pub/cases/${orderId}/epcr`
  )
  return response.data;
}
